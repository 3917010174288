// extracted by mini-css-extract-plugin
export var SliderWidth = "Services-module--SliderWidth--79abd";
export var arrowImg = "Services-module--arrowImg--a42b0";
export var card = "Services-module--card--8db90";
export var cardImg = "Services-module--cardImg--57104";
export var cardMian = "Services-module--cardMian--d50b3";
export var cardWrapper = "Services-module--cardWrapper--43051";
export var cards = "Services-module--cards--c307d";
export var cmsWebIconCOntainer = "Services-module--cmsWebIconCOntainer--bdc1b";
export var cmsWebIconCOntainerLeft = "Services-module--cmsWebIconCOntainerLeft--4a949";
export var description = "Services-module--description--41ba7";
export var heading = "Services-module--heading--550c7";
export var headingPremium = "Services-module--headingPremium--388dd";
export var mainWorkFeaturesWebCms = "Services-module--mainWorkFeaturesWebCms--52d3f";
export var portfolioArrowIcon = "Services-module--portfolioArrowIcon--6ed62";
export var portfolioArrowIconCover = "Services-module--portfolioArrowIconCover--8caad";
export var portfolioArrowRightIconCover = "Services-module--portfolioArrowRightIconCover--e4481";
export var premiumImg = "Services-module--premiumImg--3764d";
export var providingImg = "Services-module--providingImg--36022";
export var ser = "Services-module--ser--f125b";
export var teamButton = "Services-module--teamButton--77696";
export var trailBg = "Services-module--trailBg--a3374";