import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Partner.module.scss"

function Partner({ strapiData }) {
  return (
    <div className={styles.partner}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="gap-30">
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col lg={3}>
                <div className={styles.card}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{
                      height: "100px",
                      width: "100px",
                      margin: "auto",
                      background: "rgba(47, 50, 68, 1)",
                      borderRadius: "20px",
                      padding: "20px"
                    }}
                    className={styles.lottieImg}
                  />
                  <p className={styles.year}>{e?.subTitle}</p>
                  <p className={styles.Bossin}>{e?.title}</p>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Partner
