import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/ai-development-service/Banner"
import Services from "../components/ai-development-service/Services"
import ServicesMobile from "../components/ai-development-service/ServicesMobile"
import Partner from "../components/ai-development-service/Partner"
import Development from "../components/ai-development-service/Development"
import Benefits from "../components/ai-development-service/Benefits"
import Models from "../components/ai-development-service/Models"
import ModelsMobile from "../components/ai-development-service/ModelsMobile"
import Tools from "../components/ai-development-service/Tools"
import Recap from "../components/ai-development-service/Recap"
import RecapMobile from "../components/ai-development-service/RecapMobile"
import Faqs from "../components/common/Faqs2"

export const Head = ({ data }) => {
  //   const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="idle"
      />
      {/* <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/Portfolio_059cb9635f.png"
      /> */}
    </>
  )
}
const aiDevelopment = ({ data }) => {
  console.log(data)
  const heroSection = data?.strapiPage?.sections[0]
  const services = data?.strapiPage?.sections[1]
  const partner = data?.strapiPage?.sections[2]
  const recap = data?.strapiPage?.sections[3]
  const development = data?.strapiPage?.sections[4]
  const benefits = data?.strapiPage?.sections[5]
  const models = data?.strapiPage?.sections[6]
  const tools = data?.strapiPage?.sections[7]
  const faq = data?.strapiPage?.sections[10]

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 1280)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      {!isMobile && !isTablet ? (
        <Services strapiData={services} />
      ) : (
        <ServicesMobile strapiData={services} />
      )}
      <Partner strapiData={partner} />
      {!isMobile && !isTablet ? (
        <Recap strapiData={recap} />
      ) : (
        <RecapMobile strapiData={recap} />
      )}
      <Development strapiData={development} />
      <Benefits strapiData={benefits} />
      {!isMobile && !isTablet ? (
        <Models strapiData={models} />
      ) : (
        <ModelsMobile strapiData={models} />
      )}
      <Tools strapiData={tools} />
      <Faqs strapiData={faq} />
      <ContactSales bgColor="#F8F8F8" />
    </MainLayout>
  )
}

export const query = graphql`
  query aiDevelopment {
    strapiPage(slug: { eq: "ai-development-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default aiDevelopment
