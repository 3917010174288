// extracted by mini-css-extract-plugin
export var dec = "Development-module--dec--06ce8";
export var faqButton = "Development-module--faqButton--f0989";
export var faqsNum = "Development-module--faqsNum--71102";
export var faqsSub = "Development-module--faqsSub--07e7e";
export var head = "Development-module--head--dba57";
export var quesColor = "Development-module--quesColor--400d7";
export var quesColorSelected = "Development-module--quesColorSelected--8194f";
export var section = "Development-module--section--0226b";
export var serial = "Development-module--serial--7eb9a";
export var serial2 = "Development-module--serial2--f76bf";
export var textColor = "Development-module--textColor--2994a";