import React from "react"
import * as styles from "./ModelsMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const data = [
  {
    title: "Staff Augmentation",
    gif: "https://invozone-backend.s3.us-east-1.amazonaws.com/staff_augmentation_0eeca92fcb.gif",
  },
  {
    title: "Dedicated Teams",

    gif: "https://invozone-backend.s3.us-east-1.amazonaws.com/dedicated_teams_73f605dad8.gif",
  },
  {
    title: "Product Development",

    gif: "https://invozone-backend.s3.us-east-1.amazonaws.com/fixed_gigs_12b2316069.gif",
  },
]

const FeaturesMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileWebCmsFeatures}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {data?.map((e, i) => (
              <Col lg={6} md={6} xs={12} key={i}>
                <div className={styles.card}>
                  <img src={e?.image2} />
                  <h3>{e?.title}</h3>
                  <img src={e?.gif} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FeaturesMobile
