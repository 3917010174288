// extracted by mini-css-extract-plugin
export var accImg = "Benefits-module--accImg--2ac1f";
export var approachImg = "Benefits-module--approachImg--69642";
export var btn_white1_border = "Benefits-module--btn_white1_border--63371";
export var description = "Benefits-module--description--d2006";
export var engineeringSQA = "Benefits-module--engineeringSQA--5b7fd";
export var head = "Benefits-module--head--a02e4";
export var heading = "Benefits-module--heading--e13a2";
export var keyBtn = "Benefits-module--keyBtn--23c21";
export var nextGenRight = "Benefits-module--nextGenRight--142e9";
export var quesColor = "Benefits-module--quesColor--a15c4";
export var quesColorSelected = "Benefits-module--quesColorSelected--f42ae";
export var ser = "Benefits-module--ser--94a00";
export var someColor = "Benefits-module--someColor--69d68";
export var textColor = "Benefits-module--textColor--52663";