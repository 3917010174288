import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Tools.module.scss"

const techSack = [
  {
    title: "Python",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_cff335e5d5.svg",
  },
  {
    title: "TensorFlow",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/tensorflow_44d786930a.svg",
  },
  {
    title: "PyTorch",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/pytorch_281e5fa2b4.svg",
  },
  {
    title: "ChatGPT",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/chatgpt_4ab6b5f2d3.svg",
  },
  {
    title: "Keras",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/keras_08f19820fa.svg",
  },
  {
    title: "OpenCV",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/opencv_41b0189fdf.svg",
  },
  {
    title: "Scikit-learn",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/scikit_learn_03e1ef0fbb.svg",
  },
  {
    title: "Pandas",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/pandas_a7e9f277a4.svg",
  },
  {
    title: "Numpy",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/numpy_0c7710d6c4.svg",
  },
  {
    title: "Matplotlib",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/Matplotlib_d7bdbf2cf4.svg",
  },
  {
    title: "SciPy",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/scipy_f77968ecc0.svg",
  },
  {
    title: "Theano",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/theano_433f94d4f2.svg",
  },
  {
    title: "GenAI",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/gen_ai_614241c4c0.svg",
  },
  {
    title: "RAG",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/rag_b63112b20b.svg",
  },
  {
    title: "Langchain",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/langchain_b0cadcc3c1.svg",
  },
  {
    title: "LLM",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/llm_4dbf65f187.svg",
  },
  {
    title: "HuggingFace",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/hugging_face_67ed69ff21.svg",
  },
  {
    title: "NLTK",
    logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/nltk_9c75e595f0.svg",
  },
]

const Tools = ({ strapiData }) => {
  return (
    <div className={styles.advance}>
      <Container>
        <div className={styles.techBg}>
          <h2
            className={styles.heading}
            // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >Get Started With Our <br/>
          Advanced Artificial Intelligence Toolkit</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={styles.techIcon}>
            {techSack?.map((el, i) => (
              <div className={`${styles.iconssCard}`} key={i}>
                <div className={styles.technologyIcon}>
                  <div className={styles.techImg}>
                    <div>
                      <img
                        decoding="async"
                        loading="lazy"
                        // src={el?.image1[0]?.localFile?.publicURL}
                        src={el?.logo}
                        alt={el?.title}
                      />
                    </div>
                  </div>
                  <h3>{el?.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Tools
