// extracted by mini-css-extract-plugin
export var Android = "Models-module--Android--38dcf";
export var arrowDiv = "Models-module--arrowDiv--404a4";
export var arrowImg = "Models-module--arrowImg--3017b";
export var boxContent = "Models-module--boxContent--f7d1e";
export var cards = "Models-module--cards--3e300";
export var cardsContent = "Models-module--cardsContent--1b70f";
export var cardsZ = "Models-module--cardsZ--da993";
export var djangoArrowImg = "Models-module--djangoArrowImg--efca9";
export var estabBtn = "Models-module--estabBtn--91729";
export var explorrBtn = "Models-module--explorrBtn--80569";
export var finImg = "Models-module--finImg--50a1c";
export var heading = "Models-module--heading--94e23";
export var processSteps = "Models-module--processSteps--372f6";
export var product1 = "Models-module--product1--14bb9";
export var ser = "Models-module--ser--d92ae";
export var soft = "Models-module--soft--9fbc2";
export var tabImg = "Models-module--tabImg--60982";
export var tabbingCards = "Models-module--tabbingCards--0eb74";