import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Development.module.scss"
import "./Development.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button" className={styles.faqButton}>
          {isCurrentEventKey ? (
            <img
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/dropdown_open_02ac23391d.svg"

              className={styles.quesColorSelected}
              decoding="async"
              loading="lazy"
              alt="icon"
            />
          ) : (
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/dropdown_close_8aec3ac9bb.svg"

              className={styles.quesColor}
              decoding="async"
              loading="lazy"
              alt="icon"
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Faqs = ({ strapiData }) => {
  return (
    <div className={`${styles.section} `}>
      <Container>
        <h2
          className={`${styles.head} text-center`}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
         <p
          className={`${styles.dec} `}
          dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
        />
        <Row>
          <Col lg={12}>
            <div className="faqsaiAccordian">
              <Accordion defaultActiveKey={`ek-0`}>
                {strapiData?.cards?.map((el, i) => (
                  <Card key={i} border="light" style={{ overflow: "visible" }}>
                    <ContextAwareToggle eventKey={`ek-${i}`}>
                      <div className={styles.faqsNum}>
                        <span className={styles.serial2}>
                          {i === 9 ? i + 1 : "0" + (i + 1)}
                        </span>
                        <span className={styles.serial}>
                          {i === 9 ? i + 1 : "0" + (i + 1)}
                        </span>
                        <h3
                          className={`${styles.faqsSub} py-2 mb-0`}
                          type="button"
                        >
                          {el?.title}
                        </h3>
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey={`ek-${i}`}>
                      <Card.Body className={styles.textColor}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: el?.subTitle,
                          }}
                        />
                        <img
                          decoding="async"
                          loading="lazy"
                          src={el?.image1[0]?.localFile?.publicURL}
                          alt={el?.title}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                    <hr style={{ margin: 0 }} />
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Faqs
