import React from "react"
import * as styles from "./RecapMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


const cards = [
  {
    title: "Stronger Community",
    dec: "We have built an impressive community of developers, product managers, SQA engineers,",
    imagee:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/stronger_community_7d1958a903.webp",
    image2:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007395_81e8fb1b26.svg",
    image3:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007396_dc7909b9f2.svg",
  },
  {
    title: "Advanced Tech Stack",
    dec: "Our AI developers have a strong grasp of PyTorch, Keras, TensorFlow, OpenCV, Numpy, and more!",
    imagee:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/advanced_tech_stack_a09b1b5436.webp",
    image2:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007397_0068e8c5ff.svg",
    image3:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007398_b546ede4bb.svg",
  },
  {
    title: "Analytical Minds",
    dec: "Plan and organize everything with utmost precision cultivated within the analytical minds of our AI/ML devs.",
    imagee:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/analytical_minds_fb2028e8a3.webp",
    image2:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007399_1bf747df78.svg",
    image3:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007400_2403fc90ac.svg",
  },
]

const FeaturesMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileWebCmsFeatures}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {cards?.map((e, i) => (
                <Col lg={6} md={6} xs={12} key={i}>
                  <div className={styles.card}>
                    {/* <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{
                        height: "60px",
                        width: "60px",
                        margin: "0px",
                      }}
                    ></lottie-player> */}
                    <img src={e?.image2}/>
                    <h3>{e?.title}</h3>
                    <p
                    
                    >{e?.dec}</p>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FeaturesMobile
