// extracted by mini-css-extract-plugin
export var cards = "Recap-module--cards--4fee2";
export var contentWrapper = "Recap-module--contentWrapper--6984f";
export var heading = "Recap-module--heading--ad3d5";
export var image2 = "Recap-module--image2--00f20";
export var image3 = "Recap-module--image3--2afc6";
export var lineImage = "Recap-module--lineImage--030f7";
export var recap = "Recap-module--recap--d873e";
export var rightAlign = "Recap-module--rightAlign--c971b";
export var ser = "Recap-module--ser--54442";
export var subDec = "Recap-module--subDec--c3e97";
export var subHeading = "Recap-module--subHeading--cce98";