// extracted by mini-css-extract-plugin
export var Frame = "Tools-module--Frame--0d99a";
export var Rec = "Tools-module--Rec--310ee";
export var advance = "Tools-module--advance--dfbf9";
export var cir = "Tools-module--cir--57a27";
export var dots = "Tools-module--dots--78c8c";
export var for1 = "Tools-module--for1--43d8b";
export var heading = "Tools-module--heading--6e98e";
export var iconssCard = "Tools-module--iconssCard--f9c52";
export var techBg = "Tools-module--techBg--85d6d";
export var techDot = "Tools-module--techDot--4760a";
export var techIcon = "Tools-module--techIcon--3d921";
export var techImg = "Tools-module--techImg--b808f";
export var technologyIcon = "Tools-module--technologyIcon--163bd";