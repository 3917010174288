import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import "./Models.scss"
import * as styles from "./Models.module.scss"

const data = [
  {
    title: "Staff Augmentation",
    tech: [
      {
        gif: "https://invozone-backend.s3.us-east-1.amazonaws.com/staff_augmentation_0eeca92fcb.gif",
      },
    ],
  },
  {
    title: "Dedicated Teams",
    tech: [
      {
        gif: "https://invozone-backend.s3.us-east-1.amazonaws.com/dedicated_teams_73f605dad8.gif",
      },
    ],
  },
  {
    title: "Product Development",
    tech: [
      {
        gif: "https://invozone-backend.s3.us-east-1.amazonaws.com/fixed_gigs_12b2316069.gif",
      },
    ],
  },
]

const Services = ({ strapiData }) => {
  let defaultActiveTab = strapiData?.cards?.[0]?.title || "default"
  return (
    <div className={`${styles.softwareTabs} ModelsTabs`}>
      <Container>
        <p className={styles.ser}>Business Models</p>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div className={styles.Android}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveTab}
          >
            <Row className="align-items-center">
              <Col lg={5}>
                <div className={styles.soft}>
                  <Nav variant="pills" className={`businessModel`}>
                    {data?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div className={styles.tabImg}>
                          <Nav.Link eventKey={`${e?.title}`}>
                            {e?.title}
                            <img
                              className="activeArrow"
                              src={
                                "https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_95e419361d.png"
                              }
                            />
                          </Nav.Link>
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={7}>
                <div className="tabs">
                  <Tab.Content className="reactTab">
                    {data?.map((e, i) => (
                      <Tab.Pane eventKey={`${e?.title}`} key={i}>
                        <div className={styles.boxContent}>
                          {e?.tech &&
                            e?.tech?.map((el, i) => <img src={el?.gif} />)}
                        </div>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Services
