import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Recap.module.scss"

const cards = [
  {
    title: "Stronger Community",
    dec: "We have built an impressive community of developers, product managers, SQA engineers.",
    imagee:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/stronger_community_7d1958a903.webp",
    image2:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007395_81e8fb1b26.svg",
    image3:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007396_dc7909b9f2.svg",
  },
  {
    title: "Advanced Tech Stack",
    dec: "Our AI developers have a strong grasp of PyTorch, Keras, TensorFlow, OpenCV, Numpy, and more!",
    imagee:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/advanced_tech_stack_a09b1b5436.webp",
    image2:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007397_0068e8c5ff.svg",
    image3:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007398_b546ede4bb.svg",
  },
  {
    title: "Analytical Minds",
    dec: "Plan and organize everything with utmost precision cultivated within the analytical minds of our AI/ML devs.",
    imagee:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/analytical_minds_fb2028e8a3.webp",
    image2:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007399_1bf747df78.svg",
    image3:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007400_2403fc90ac.svg",
  },
]

function Recap({ strapiData }) {
  const [activeImage, setActiveImage] = useState(cards[0].imagee)

  const handleMouseEnter = imagee => {
    setActiveImage(imagee)
  }

  return (
    <div className={styles.recap}>
      <Container>
        <p className={styles.ser}>Why Choose?</p>
        <h3
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row>
          <Col lg={7}>
            <img
              decoding="async"
              loading="lazy"
              src={activeImage}
              alt="Active"
            />
          </Col>

          <Col lg={5}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_647_dc488efc9e.png"
              }
              className={styles.lineImage}
            />
            {cards?.map((e, i) => (
              <div
                className={`${styles.cards} ${
                  i === 1 ? styles.rightAlign : ""
                }`}
                onMouseEnter={() => handleMouseEnter(e.imagee)}
                key={i}
              >
                <img
                  src={e?.image2}
                  style={{ height: "60px", width: "60px" }}
                  alt={e.title}
                  className={styles.image2}
                />
                <img
                  src={e?.image3}
                  style={{ height: "60px", width: "60px" }}
                  alt={e.title}
                  className={styles.image3}
                />
                <div className={styles.contentWrapper}>
                  <h4 className={styles.subHeading}>{e.title}</h4>
                  <p className={styles.subDec}>{e.dec}</p>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Recap
